<!-- 
  Attention: If you are going to update this file, please read ./modules/gitp/api/ApiController.php => actionForwardPost() and follow the mentioned steps. 
-->

<template>
  <div class="quiz">
    <!-- Quiz -->
    <div
      v-if="quizData.inProgress"
      class="quiz__inner"
      :class="{ 'quiz__inner--no-shadow': quizData.currentStep >= 2 && isNarrow }"
    >
      <section
        v-if="quizData.currentStep < 2 || !isNarrow"
        class="quiz__double-column quiz__double-column--left"
      >
        <the-quiz-explanation :explanation-copy="explanation">
          <template #button>
            <div class="quiz__back-button-wrapper">
              <button
                v-if="explanation.button && quizData.currentStep > 1"
                class="quiz__back-button"
                @click="backButtonClickHandler"
              >
                <i class="fas fa-chevron-left" />
                <span>{{ explanation.button }}</span>
              </button>
            </div>
          </template>
        </the-quiz-explanation>
      </section>

      <slot
        v-if="!formData"
        name="form"
      />

      <section
        v-else
        class="quiz__double-column quiz__double-column--right"
      >
        <button
          v-if="quizData.currentStep >= 2 && isNarrow"
          class="quiz__back-button quiz__back-button--top"
          @click="backButtonClickHandler"
        >
          <i class="fas fa-chevron-left" />
          <span>{{ explanation.button }}</span>
        </button>

        <the-quiz-questionnaire
          :current-step="quizData.currentStep"
          :questionnaire-copy="explanation"
          :questions="quizQuestions"
          :answers="quizAnswers"
          :saved-answer="quizData.answers[quizData.currentStep]"
          @on-answer-click-handler="answerClickHandler"
        />
      </section>
    </div>

    <!-- Results -->
    <div
      v-if="!quizData.inProgress && results"
      class="quiz__inner"
    >
      <section
        class="quiz__double-column quiz__double-column--left quiz__double-column--column-large quiz__double-column--reduced-padding"
      >
        <the-quiz-explanation :explanation-copy="resultsCopy">
          <template
            v-if="copy.quizResultsCopy.button"
            #button
          >
            <the-modal>
              <template #toggle-content>
                <div class="button--primary">
                  {{ copy.quizResultsCopy.button }}
                </div>
              </template>

              <template #modal-content>
                <div
                  v-if="!preferences"
                  class="quiz__results-modal"
                >
                  <h3 class="quiz__results-modal-title">
                    {{ copy.sendReportCopy.title }}
                  </h3>

                  <div class="quiz__dots quiz__dots--large" />

                  <form class="quiz__results-modal-form">
                    <label
                      v-for="input in copy.sendReportCopy.checkboxes"
                      :key="input.name"
                      class="quiz__results-modal-input-group"
                    >
                      <input
                        ref="formPreferences"
                        type="checkbox"
                        :name="input.name"
                      >
                      {{ input.label }}
                    </label>

                    <button
                      type="submit"
                      class="button--primary button--content-width"
                      @click="sendRequestResultsClickHandler"
                    >
                      {{ copy.sendReportCopy.button }}
                    </button>
                  </form>
                </div>

                <div
                  v-else-if="formSentSuccessfully"
                  class="quiz__results-modal"
                >
                  <h3 class="quiz__results-modal-title">
                    {{ copy.sendReportCopy.success.title }}
                  </h3>

                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    class="quiz__results-modal-success-message"
                    v-html="copy.sendReportCopy.success.noMailMessage"
                  />
                  <!--eslint-enable-->
                </div>

                <div
                  v-else-if="formSentSuccessfully === false"
                  class="quiz__results-modal"
                >
                  <h3 class="quiz__results-modal-title">
                    {{ copy.sendReportCopy.error.title }}
                  </h3>

                  <div class="quiz__results-modal-success-message">
                    {{ copy.sendReportCopy.error.message }}
                  </div>
                </div>
              </template>
            </the-modal>
          </template>
        </the-quiz-explanation>
      </section>

      <section
        class="quiz__double-column quiz__double-column--right quiz__double-column--reduced-padding"
      >
        <the-quiz-results
          :copy="copy.quizResultsCopy"
          :results="results"
        />
      </section>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";

import TheQuizExplanation from "./TheQuizExplanation.vue";
import TheQuizQuestionnaire from "./TheQuizQuestionnaire.vue";
import TheQuizResults from "./TheQuizResults.vue";
import TheModal from "./TheModal.vue";

const BASE_SCORE = 0;
const TABLET_MEDIA_QUERY = "(max-width: 768px)";

export default {
  name: "TheQuiz",
  components: {
    TheQuizExplanation,
    TheQuizQuestionnaire,
    TheQuizResults,
    TheModal
  },
  props: {
    formHandle: {
      required: true,
      type: String
    },
    questions: {
      required: true,
      type: Array
    },
    answers: {
      required: true,
      type: Object
    },
    copy: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      nawForm: null,
      formData: null,
      quizQuestions: null,
      quizAnswers: null,
      quizMinMax: {},
      quizData: {
        inProgress: true,
        currentStep: 0,
        answers: {},
        roleScores: {}
      },
      isNarrow: true,
      results: null,
      preferences: null,
      formSentSuccessfully: null
    };
  },
  computed: {
    explanation() {
      return !this.formData ? this.copy.formExplanationCopy : this.copy.quizExplanationCopy;
    },
    resultsCopy() {
      const staticCopy = this.$props.copy.quizResultsCopy;

      return {
        tag: staticCopy.tag,
        title: this.results.matchedRole.resultTitle,
        description: this.results.matchedRole.resultDescription,
        info: staticCopy.info
      };
    }
  },
  mounted() {
    this.setupUI();
    this.setupQuiz();
  },
  methods: {
    setupUI() {
      this.nawForm = document.querySelector(`form.quiz__form`);

      this.removeFooter();
      this.addRequiredAttributes();
      this.addEventListener();
      this.checkIfViewportIsNarrow();
    },
    setupQuiz() {
      this.cleanTableData();
      this.setSumScoreWeights();
      this.setRoles();
      this.applyBaseScores();
      this.calculateMinAndMaxValues();
    },
    addEventListener() {
      document
        .querySelector("button[type=submit]")
        .addEventListener("click", this.submitEventHandler);
    },
    addRequiredAttributes() {
      const requiredFormInputs = document.querySelectorAll(`form.quiz__form input[data-required]`);

      requiredFormInputs.forEach(input => {
        input.setAttribute("required", "");
      });
    },
    removeFooter() {
      const footerTop = document.querySelector(".footer-top");
      const footerBottom = document.querySelector(".footer-bottom")
      if(footerTop) { footerTop.remove(); }
      if(footerBottom) { footerBottom.remove(); }
    },
    checkIfViewportIsNarrow() {
      const mediaQuery = window.matchMedia(TABLET_MEDIA_QUERY);

      mediaQuery.addEventListener("change", this.mediaQueryChangeHandler);
      this.mediaQueryChangeHandler(mediaQuery);
    },
    showError() {
      this.nawForm.reportValidity();
    },
    formIsValid() {
      return this.nawForm.checkValidity();
    },
    cleanTableData() {
      const properties = ["negativeOne", "negativeTwo", "neutral", "positiveOne", "positiveTwo"];

      this.quizAnswers = _.filter(this.$props.answers, answer => {
        return properties.includes(answer.key);
      });

      this.quizQuestions = this.$props.questions.map(question => {
        const weights = Object.keys(question.weights[0])
          .filter(key => properties.includes(key))
          .reduce((obj, key) => {
            obj[key] = Number(question.weights[0][key]);
            return obj;
          }, {});

        return {
          ...question,
          weights
        };
      });
    },
    setRoles() {
      this.quizData.roles = this.quizQuestions.reduce((previousValue, currentValue) => {
        currentValue.roles.forEach(role => {
          if (!previousValue.includes(role.name)) previousValue.push(role.name);
        });

        return previousValue;
      }, []);
    },
    findMatchedRole(scores) {
      const highestScoringRoleName = Object.keys(scores)[0];

      return _.find(this.$props.copy.quizRolesCopy, role => role.title === highestScoringRoleName);
    },
    sortScores(scores) {
      return Object.fromEntries(
        Object.entries(scores)
          .sort(([, a], [, b]) => a - b)
          .reverse()
      );
    },
    applyBaseScores() {
      this.quizData.roles.forEach(role => {
        this.quizData.roleScores[role] = BASE_SCORE;
      });
    },
    setSumScoreWeights() {
      // Add +10 to each weight in the question category
      this.quizQuestions = this.quizQuestions.map(question => {
        return {
          ...question,
          sumScoreWeights: Object.entries(question.weights).reduce((acc, curr) => {
            return {
              ...acc,
              [curr[0]]: curr[1] + 10
            };
          }, {})
        };
      });
    },
    nextStep() {
      this.quizData.currentStep++;
    },
    previousStep() {
      this.quizData.currentStep--;
    },
    showResults() {
      const scores = this.sortScores(this.calculateFinalScores(this.quizData.roleScores));

      this.results = {
        userData: this.formData,
        answers: this.quizData.answers,
        matchedRole: this.findMatchedRole(scores),
        scores
      };

      this.quizData.inProgress = false;

      this.handlePostToSharpSpring(this.results);
    },

    // Quiz handlers
    saveFormData() {
      const nawFormData = new FormData(this.nawForm);
      const values = {};

      for (const [key, value] of nawFormData) {
        values[key] = value;
      }

      this.formData = values;
      this.nextStep();
    },
    saveAnswer(answer) {
      const roles = answer.question.roles;

      roles.forEach(role => {
        // Check if question is already anwered
        if (this.quizData.answers[answer.id]) {
          const currentRoleScore = this.getRoleScore(this.quizData.answers[answer.id], role);

          // Deduct score of last given answer
          this.quizData.roleScores[role.name] = this.calculateRoleScore(
            this.quizData.roleScores[role.name],
            currentRoleScore,
            "-"
          );
        }

        const newRoleScore = this.getRoleScore(answer, role);

        // Add score to total role score
        this.quizData.roleScores[role.name] = this.calculateRoleScore(
          this.quizData.roleScores[role.name],
          newRoleScore,
          "+"
        );
      });

      const answerObject = {
        id: answer.id,
        question: answer.question,
        userInput: answer.userInput
      };

      this.quizData.answers[answer.id] = answerObject;
    },
    calculateFinalScores(scores) {
      const finalScores = {};

      Object.entries(scores).forEach(score => {
        const roleName = score[0];
        const totalScore = score[1];

        const parsedScore = parseFloat(totalScore.toFixed(3));
        const minMaxScore = this.quizMinMax[roleName];
        const range = minMaxScore.max - minMaxScore.min;

        const finalScore = (parsedScore - minMaxScore.min) * (100 / range);

        finalScores[roleName] = finalScore;
      });

      return finalScores;
    },
    calculateMinAndMaxValues() {
      this.quizQuestions.forEach(question => {
        const minScore = parseFloat(question.weights.negativeTwo);
        const maxScore = parseFloat(question.weights.positiveTwo);

        question.roles.forEach(role => {
          if (!this.quizMinMax[role.name]) {
            this.quizMinMax[role.name] = {};

            this.quizMinMax[role.name].min = 30;
            this.quizMinMax[role.name].max = 30;
          }

          this.quizMinMax[role.name].min += minScore;
          this.quizMinMax[role.name].max += maxScore;
        });

        // Round all final calculations
        Object.entries(this.quizMinMax).forEach(property => {
          this.quizMinMax[property[0]] = {
            min: parseFloat(property[1].min.toFixed(3)),
            max: parseFloat(property[1].max.toFixed(3))
          };
        });
      });
    },
    calculateRoleScore(totalScore, score, operator) {
      const operators = {
        "-": (a, b) => a - b,
        "+": (a, b) => a + b
      };

      return operators[operator](totalScore, score);
    },
    getRoleScore(answer, role) {
      if (role.mirrorWeights) {
        const mirroredAnswerKeys = {
          positiveTwo: "negativeTwo",
          positiveOne: "negativeOne",
          neutral: "neutral",
          negativeOne: "positiveOne",
          negativeTwo: "positiveTwo"
        };

        return answer.question.sumScoreWeights[mirroredAnswerKeys[answer.userInput.key]];
      }

      return answer.question.sumScoreWeights[answer.userInput.key];
    },
    buildGenerateReportObject() {
      const scores = Object.entries(this.results.scores).map(item => {
        return { category: item[0], percentage: item[1] };
      });

      const answers = Object.values(this.quizData.answers).map(answer => {
        return {
          question: answer.question.question,
          answer: answer.userInput.label
        };
      });

      return {
        naw: {
          firstname: this.formData.firstname,
          lastname: this.formData.lastname,
          email: this.formData.email,
          motivation: this.formData.motivation,
          branche: this.formData.branche,
          organisation_size: this.formData.organisation_size,
          role: this.formData.role,
          consent: this.formData.consent === "1"
        },
        percentages: scores,
        quiz: answers,
        preferences: this.preferences
      };
    },
    setPreferences() {
      this.preferences = {};

      this.$refs.formPreferences.forEach(preference => {
        this.preferences[preference.name] = preference.checked;
      });
    },
    async sendReportData() {
      const reportData = this.buildGenerateReportObject();

      try {
        // Before updating this endpoint/Quiz element, please read ./modules/gitp/api/ApiController.php => actionForwardPost()
        const response = await fetch(
          `/proxy?endpoint=${process.env.VUE_APP_TALENTMANAGER_API_URL}/quiz/create-rapport`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(reportData)
          }
        );

        if (response.errors) {
          this.formSentSuccessfully = false;
          return;
        }

        this.formSentSuccessfully = true;
      } catch (err) {
        this.formSentSuccessfully = false;
      }
    },

    // Track events
    trackEvent(event) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(event);
    },
    trackEventAnswer({ id }) {
      const event = {
        event: "hrSuperSkillQuiz",
        questionAnswered: id
      };

      this.trackEvent(event);
    },
    trackEventCompleteQuestionnaire() {
      const event = {
        event: "hrSuperSkillQuizFinished",
        superSkillResult: this.results
      };

      this.trackEvent(event);
    },
    trackEventReportSent() {
      const event = {
        event: "hrSuperSkillReportSend",
        superSkillResult: this.results
      };

      this.trackEvent(event);
    },

    // event handlers
    submitEventHandler(e) {
      e.preventDefault();

      if (!this.formIsValid()) {
        this.showError();
        return null;
      }

      this.saveFormData();
    },
    backButtonClickHandler() {
      this.previousStep();
    },
    mediaQueryChangeHandler(e) {
      this.isNarrow = e.matches;
    },
    answerClickHandler(answer) {
      this.saveAnswer(answer);
      this.trackEventAnswer(answer);

      if (this.quizData.currentStep + 1 <= this.quizQuestions.length) {
        this.nextStep();
        return;
      }

      this.showResults();
      this.trackEventCompleteQuestionnaire();
    },
    async sendRequestResultsClickHandler(e) {
      e.preventDefault();

      this.setPreferences();

      await this.sendReportData();

      if (this.formSentSuccessfully) {
        this.trackEventReportSent();
      }
    },

    // SharpSpring handlers
    handlePostToSharpSpring(results) {
      const url = this.buildSharpspringUrl(results);
      this.postToSharpspring(url);
    },
    buildSharpspringUrl(results) {
      const url = new URL(`${process.env.VUE_APP_SHARPSPRING_ENDPOINT}${this.formHandle}/jsonp`);
      this.getFormData(results).forEach((value, key) => {
        if (process.env.VUE_APP_SHARPSPRING_SUBMISSION_FORMATTING_ENABLED === "true") {
          // Transform special characters into normal letters, to prevent erros in sharpspring submissions.
          // (example: "àáâäæãåå b çćč d èéêëēėę f g h îïíīįì j k ł m ñń ôöòóœøōõ p q r ßśš t ûüùúū v w x ÿ žźż")
          const combining = /[\u0300-\u036F]/g;
          let transformedValue = value.normalize("NFKD").replace(combining, "");
          transformedValue = transformedValue.replace(/[&/\\#,+()$~^%.'"`:*?<>{}]/g, "");
          url.searchParams.append(key, transformedValue);
        } else {
          const url = new URL(
            `${process.env.VUE_APP_SHARPSPRING_ENDPOINT}${this.formHandle}/jsonp`
          );
          this.getFormData(results).forEach((value, key) => {
            url.searchParams.append(key, value);
          });
        }
      });
      return url.href.replace("%27", "");
    },
    getFormData(results) {
      const role = { role: results.matchedRole.title };
      const userData = this.omitFreeformValues(results.userData);

      const answers = Object.values(results.answers).reduce(function (acc, currentValue) {
        const question = currentValue.question.question;
        const answer = currentValue.userInput.label;
        acc[question] = answer;
        return acc;
      }, {});

      let formData = new FormData();
      formData = this.appendDataToFormData(formData, userData);
      formData = this.appendDataToFormData(formData, answers);
      formData = this.appendDataToFormData(formData, role);
      return formData;
    },
    omitFreeformValues(obj) {
      // omit keys value pair, where key includes ['freeform','action','formHash'] from object
      const cleanedUserData = Object.keys(obj)
        .filter(key => !key.includes("freeform") && key !== "action" && key !== "formHash")
        .reduce((temp_obj, key) => {
          temp_obj[key] = obj[key];
          return temp_obj;
        }, {});
      return cleanedUserData;
    },
    appendDataToFormData(formData, obj) {
      Object.keys(obj).forEach(key => {
        formData.append(key, obj[key]);
      });
      return formData;
    },
    async postToSharpspring(url) {
      // Post to sharpspring: Add extra catch/error checks, because api doesn't return a response and api will always land in the catch
      axios({ method: "post", url })
        .then(function (response) {
          console.log({
            status:
              "The request was made and the server responded with a status code that falls in the range of 2xx",
            response
          });
        })
        .catch(function (error) {
          if (error.response) {
            console.log({
              status:
                "The request was made and the server responded with a status code that falls out of the range of 2xx",
              response: error.response
            });
          } else if (error.request) {
            console.log({
              status: "The request was made but no response was received",
              request: error.request
            });
          } else {
            console.log({
              status: "Something happened in setting up the request that triggered an Error",
              message: error.message,
              "config:": error.config
            });
          }
        });
    }
  }
};
</script>
