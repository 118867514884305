<template>
  <div class="grid lg:grid-cols-12">
    <div :class="GRID_STYLES[size]">
      <slot />
    </div>
  </div>
</template>

<script>
const GRID_STYLES = {
  's': 'lg:col-start-3 lg:col-span-8',
  'm': 'lg:col-start-2 lg:col-span-10',
  'l': 'lg:col-start-1 lg:col-span-12'
};

export default {
  props: {
    size: {
      type: String,
      required: false,
      default: 'm',
      validator: (value) => value in GRID_STYLES
    },
  },
  setup() {
    return {
      GRID_STYLES
    };
  }
};
</script>
