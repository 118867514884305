<template>
  <section 
    id="cookie-declaration" 
    class="block block--embedded-content"
  >
    <div class="np-container">
      <div
        ref="cookieDeclarationRef"
        class="block__content"
      />
    </div>
  </section>
</template>
  
<script>
import { useCookiebot } from '@ambitiondev/vue-cookiebot';

export default {
  name: "TwVueCookiebotDeclaration",
  mounted(){
    const consentPageRef = this.$refs.cookieDeclarationRef;
    const { cookieDeclaration } = useCookiebot();
    cookieDeclaration(consentPageRef);
  }
}
</script>